import { CvInfo } from 'components/organisms/Onboarding/interface';
import {
  BlockAccountPayload,
  ChangePasswordPayload,
  CreateMyCVTemplateData,
  CreateMyCVTemplatePayload,
  CreatePasswordPayload,
  CvCategoryData,
  CvTemplateData,
  CvTransactionData,
  DeleteMyCVTemplateData,
  EditProfilePayload,
  Error,
  ForgotPasswordPayload,
  GetProfilePayload,
  LoginData,
  LoginPayload,
  MajorData,
  MyCVTemplateData,
  OccupationData,
  OccupationDetailsData,
  OnboardingRequirementData,
  Profile,
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileReference,
  RegisterPayload,
  RegisterSuccessData,
  ResendConfirmationEmailPayload,
  SampleCVData,
  SharedCVDetailsPayload,
  SubmitOnboardingData,
  UpdateMyCVTemplateData,
  UpdateOrderingData,
  UpdateOrderingPayload,
  VerifyEmailPayload,
  VerifyEmailSuccessData,
} from './index';
import {
  AddClicksPayload,
  CheckVerifyEmailPayload,
  ContactUsPayload,
  FacebookLoginPayload,
  FilterCvTemplatePayload,
  LinkedInLoginPayload,
  SampleCVDetailPayload,
  SampleCVPayload,
} from './actions-payload.interfaces';
import { ProfileLanguage, ProfileVolunteer, ReferralData } from './data.interfaces';

export enum actionTypes {
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',

  LOGOUT = 'LOGOUT',
  RESET_STORE = 'RESET_STORE',

  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED',

  REGISTER = 'REGISTER',
  REGISTER_SUCCESS_ACTION = 'REGISTER_SUCCESS_ACTION',
  REGISTER_FAILED = 'REGISTER_FAILED',

  VERIFY_EMAIL = 'VERIFY_EMAIL',
  VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED',

  CREATE_PASSWORD = 'CREATE_PASSWORD',

  RESEND_CONFIRMATION_EMAIL = 'RESEND_CONFIRMATION_EMAIL',
  RESEND_CONFIRMATION_EMAIL_SUCCESS = 'RESEND_CONFIRMATION_EMAIL_SUCCESS',
  RESEND_CONFIRMATION_EMAIL_FAILED = 'RESEND_CONFIRMATION_EMAIL_FAILED',

  GET_ONBOARDING_REQUIREMENT = 'ONBOARDING_REQUIREMENT',
  GET_ONBOARDING_REQUIREMENT_SUCCESS = 'ONBOARDING_REQUIREMENT_SUCCESS',
  GET_ONBOARDING_REQUIREMENT_FAILED = 'ONBOARDING_REQUIREMENT_FAILED',

  SAVE_CV_INFO = 'SAVE_CV_INFO',

  SUBMIT_ONBOARDING = 'SUBMIT_ONBOARDING',
  SUBMIT_ONBOARDING_SUCCESS = 'SUBMIT_ONBOARDING_SUCCESS',
  SUBMIT_ONBOARDING_FAILED = 'SUBMIT_ONBOARDING_FAILED',

  GET_EDUCATION = 'GET_EDUCATION',
  GET_EDUCATION_SUCCESS = 'GET_EDUCATION_SUCCESS',
  GET_EDUCATION_FAILED = 'GET_EDUCATION_FAILED',

  ADD_EDUCATION = 'ADD_EDUCATION',
  ADD_EDUCATION_SUCCESS = 'ADD_EDUCATION_SUCCESS',
  ADD_EDUCATION_FAILED = 'ADD_EDUCATION_FAILED',

  UPDATE_EDUCATION = 'UPDATE_EDUCATION',
  UPDATE_EDUCATION_SUCCESS = 'UPDATE_EDUCATION_SUCCESS',
  UPDATE_EDUCATION_FAILED = 'UPDATE_EDUCATION_FAILED',
  UPDATE_EDUCATION_ORDERING = 'UPDATE_EDUCATION_ORDERING',

  REMOVE_EDUCATION = 'REMOVE_EDUCATION',
  REMOVE_EDUCATION_SUCCESS = 'REMOVE_EDUCATION_SUCCESS',
  REMOVE_EDUCATION_FAILED = 'REMOVE_EDUCATION_FAILED',

  GET_EXPERIENCE = 'GET_EXPERIENCE',
  GET_EXPERIENCE_SUCCESS = 'GET_EXPERIENCE_SUCCESS',
  GET_EXPERIENCE_FAILED = 'GET_EXPERIENCE_FAILED',

  ADD_EXPERIENCE = 'ADD_EXPERIENCE',
  ADD_EXPERIENCE_SUCCESS = 'ADD_EXPERIENCE_SUCCESS',
  ADD_EXPERIENCE_FAILED = 'ADD_EXPERIENCE_FAILED',

  UPDATE_EXPERIENCE = 'UPDATE_EXPERIENCE',
  UPDATE_EXPERIENCE_SUCCESS = 'UPDATE_EXPERIENCE_SUCCESS',
  UPDATE_EXPERIENCE_FAILED = 'UPDATE_EXPERIENCE_FAILED',
  UPDATE_EXPERIENCE_ORDERING = 'UPDATE_EXPERIENCE_ORDERING',

  REMOVE_EXPERIENCE = 'REMOVE_EXPERIENCE',
  REMOVE_EXPERIENCE_SUCCESS = 'REMOVE_EXPERIENCE_SUCCESS',
  REMOVE_EXPERIENCE_FAILED = 'REMOVE_EXPERIENCE_FAILED',

  UPDATE_ORDERING = 'UPDATE_ORDERING',
  UPDATE_ORDERING_SUCCESS = 'UPDATE_ORDERING_SUCCESS',
  UPDATE_ORDERING_FAILED = 'UPDATE_ORDERING_FAILED',

  GET_MAJOR = 'MAJOR',
  GET_MAJOR_SUCCESS = 'MAJOR_SUCCESS',
  GET_MAJOR_FAILED = 'MAJOR_FAILED',

  GET_LIST_CV_TEMPLATE = 'LIST_CV_TEMPLATE',
  GET_LIST_CV_TEMPLATE_SUCCESS = 'LIST_CV_TEMPLATE_SUCCESS',
  GET_LIST_CV_TEMPLATE_FAILED = 'LIST_CV_TEMPLATE_FAILED',

  GET_CV_TRANSACTION_LIST = 'CV_TRANSACTION_LIST',
  GET_CV_TRANSACTION_LIST_SUCCESS = 'CV_TRANSACTION_LIST_SUCCESS',
  GET_CV_TRANSACTION_LIST_FAILED = 'CV_TRANSACTION_LIST_FAILED',

  SET_CURRENT_CV_ID = 'SET_CURRENT_CV_ID',
  SET_CURRENT_CV_COLOR = 'SET_CURRENT_CV_COLOR',
  SET_CURRENT_CV_FONT = 'SET_CURRENT_CV_FONT',
  SET_CURRENT_CV_FONT_SIZE = 'SET_CURRENT_CV_FONT_SIZE',
  SET_SHOW_PROFICIENCY = 'SET_SHOW_PROFICIENCY',
  SET_CV_SELECTED = 'SET_CV_SELECTED',
  SET_SHOW_AVATAR = 'SET_SHOW_AVATAR',

  GET_MY_CV_TEMPLATE_LIST = 'GET_MY_CV_TEMPLATE_LIST',
  GET_MY_CV_TEMPLATE_LIST_SUCCESS = 'GET_MY_CV_TEMPLATE_LIST_SUCCESS',
  GET_MY_CV_TEMPLATE_LIST_FAILED = 'GET_MY_CV_TEMPLATE_LIST_FAILED',

  GET_STRIPE_CHECKOUT_SESSION_ID = 'GET_STRIPE_CHECKOUT_SESSION_ID',
  GET_STRIPE_CHECKOUT_SESSION_ID_SUCCESS = 'GET_STRIPE_CHECKOUT_SESSION_ID_SUCCESS',
  GET_STRIPE_CHECKOUT_SESSION_ID_FAILED = 'GET_STRIPE_CHECKOUT_SESSION_ID_FAILED',

  SET_CV_TRANSACTION_ID = 'SET_CV_TRANSACTION_ID',

  PURCHASE_CV_TEMPLATE_PAYPAL = 'PURCHASE_CV_TEMPLATE_PAYPAL',
  PURCHASE_CV_TEMPLATE_PAYPAL_SUCCESS = 'PURCHASE_CV_TEMPLATE_PAYPAL_SUCCESS',
  PURCHASE_CV_TEMPLATE_PAYPAL_FAILED = 'PURCHASE_CV_TEMPLATE_PAYPAL_FAILED',

  CANCEL_CV_TRANSACTION = 'CANCEL_CV_TRANSACTION',
  CANCEL_CV_TRANSACTION_SUCCESS = 'CANCEL_CV_TRANSACTION_SUCCESS',
  CANCEL_CV_TRANSACTION_FAILED = 'CANCEL_CV_TRANSACTION_FAILED',

  CREATE_MY_CV_TEMPLATE = 'CREATE_MY_CV_TEMPLATE',
  CREATE_MY_CV_TEMPLATE_SUCCESS = 'CREATE_MY_CV_TEMPLATE_SUCCESS',
  CREATE_MY_CV_TEMPLATE_FAILED = 'CREATE_MY_CV_TEMPLATE_FAILED',

  UPDATE_MY_CV_TEMPLATE = 'UPDATE_MY_CV_TEMPLATE',
  UPDATE_MY_CV_TEMPLATE_SUCCESS = 'UPDATE_MY_CV_TEMPLATE_SUCCESS',
  UPDATE_MY_CV_TEMPLATE_FAILED = 'UPDATE_MY_CV_TEMPLATE_FAILED',

  DELETE_MY_CV_TEMPLATE = 'DELETE_MY_CV_TEMPLATE',
  DELETE_MY_CV_TEMPLATE_SUCCESS = 'DELETE_MY_CV_TEMPLATE_SUCCESS',
  DELETE_MY_CV_TEMPLATE_FAILED = 'DELETE_MY_CV_TEMPLATE_FAILED',

  GET_PROFILE = 'GET_PROFILE',
  GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILED = 'GET_PROFILE_FAILED',

  CHECK_EMAIL_VERIFY = 'CHECK_EMAIL_VERIFY',
  CHECK_EMAIL_VERIFY_SUCCESS = 'CHECK_EMAIL_VERIFY_SUCCESS',
  CHECK_EMAIL_VERIFY_FAILED = 'CHECK_EMAIL_VERIFY_FAILED',

  EDIT_PROFILE = 'EDIT_PROFILE',
  EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS',
  EDIT_PROFILE_FAILED = 'EDIT_PROFILE_FAILED',

  BLOCK_ACCOUNT = 'BLOCK_ACCOUNT',

  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED',

  GET_TERM = 'TERM',
  GET_TERM_SUCCESS = 'TERM_SUCCESS',
  GET_TERM_FAILED = 'TERM_FAILED',

  GET_POLICY = 'POLICY',
  GET_POLICY_SUCCESS = 'POLICY_SUCCESS',
  GET_POLICY_FAILED = 'POLICY_FAILED',

  GET_SAMPLE_CV_LIST = 'GET_SAMPLE_CV_LIST',
  GET_SAMPLE_CV_LIST_SUCCESS = 'GET_SAMPLE_CV_LIST_SUCCESS',
  GET_SAMPLE_CV_LIST_FAILED = 'GET_SAMPLE_CV_LIST_FAILED',

  GET_CV_CATEGORY_LIST = 'GET_CV_CATEGORY_LIST',
  GET_CV_CATEGORY_LIST_SUCCESS = 'GET_CV_CATEGORY_LIST_SUCCESS',
  GET_CV_CATEGORY_LIST_FAILED = 'GET_CV_CATEGORY_LIST_FAILED',

  GET_CV_DETAIL_PAGE = 'GET_CV_DETAIL_PAGE',
  GET_CV_DETAIL_PAGE_SUCCESS = 'GET_CV_DETAIL_PAGE_SUCCESS',
  GET_CV_DETAIL_PAGE_FAILED = 'GET_CV_DETAIL_PAGE_FAILED',
  GET_OCCUPATION_LIST = 'GET_OCCUPATION_LIST',
  GET_OCCUPATION_LIST_SUCCESS = 'GET_OCCUPATION_LIST_SUCCESS',
  GET_OCCUPATION_LIST_FAILED = 'GET_OCCUPATION_LIST_FAILED',

  GET_OCCUPATION_DETAILS = 'GET_OCCUPATION_DETAILS',
  GET_OCCUPATION_DETAILS_SUCCESS = 'GET_OCCUPATION_DETAILS_SUCCESS',
  GET_OCCUPATION_DETAILS_FAILED = 'GET_OCCUPATION_DETAILS_FAILED',

  RESET_OCCUPATION_DETAILS = 'RESET_OCCUPATION_DETAILS',

  GET_SHARED_CV_DETAIL = 'GET_SHARED_CV_DETAIL',
  GET_SHARED_CV_DETAIL_SUCCESS = 'GET_SHARED_CV_DETAIL_SUCCESS',
  GET_SHARED_CV_DETAIL_FAILED = 'GET_SHARED_CV_DETAIL_FAILED',

  GET_JOB_SCOPE_LIST = 'GET_JOB_LIST_SCOPE',
  GET_JOB_SCOPE_LIST_SUCCESS = 'GET_JOB_SCOPE_LIST_SUCCESS',
  GET_JOB_SCOPE_LIST_FAILED = 'GET_JOB_SCOPE_LIST_FAILED',

  GET_DEGREE_LIST = 'GET_DEGREE_LIST',
  GET_DEGREE_LIST_SUCCESS = 'GET_DEGREE_LIST_SUCCESS',
  GET_DEGREE_LIST_FAILED = 'GET_DEGREE_LIST_FAILED',

  GET_SCHOOL_LIST = 'GET_SCHOOL_LIST',
  GET_SCHOOL_LIST_SUCCESS = 'GET_SCHOOL_LIST_SUCCESS',
  GET_SCHOOL_LIST_FAILED = 'GET_SCHOOL_LIST_FAILED',

  GET_COMPANY_LIST = 'GET_COMPANY_LIST',
  GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS',
  GET_COMPANY_LIST_FAILED = 'GET_COMPANY_LIST_FAILED',

  GET_JOB_TYPE_LIST = 'GET_JOB_TYPE_LIST',
  GET_JOB_TYPE_LIST_SUCCESS = 'GET_JOB_TYPE_LIST_SUCCESS',
  GET_JOB_TYPE_LIST_FAILED = 'GET_JOB_TYPE_LIST_FAILED',

  ADD_REFERENCE = 'ADD_REFERENCE',
  ADD_REFERENCE_SUCCESS = 'ADD_REFERENCE_SUCCESS',
  ADD_REFERENCE_FAILED = 'ADD_REFERENCE_FAILED',

  GET_REFERENCE = 'GET_REFERENCE',
  GET_REFERENCE_SUCCESS = 'GET_REFERENCE_SUCCESS',
  GET_REFERENCE_FAILED = 'GET_REFERENCE_FAILED',

  UPDATE_REFERENCE = 'UPDATE_REFERENCE',
  UPDATE_REFERENCE_SUCCESS = 'UPDATE_REFERENCE_SUCCESS',
  UPDATE_REFERENCE_FAILED = 'UPDATE_REFERENCE_FAILED',
  UPDATE_REFERENCE_ORDERING = 'UPDATE_REFERENCE_ORDERING',

  DELETE_REFERENCE = 'DELETE_REFERENCE',
  DELETE_REFERENCE_SUCCESS = 'DELETE_REFERENCE_SUCCESS',
  DELETE_REFERENCE_FAILED = 'DELETE_REFERENCE_FAILED',

  ADD_CERTIFICATE = 'ADD_CERTIFICATE',
  ADD_CERTIFICATE_SUCCESS = 'ADD_CERTIFICATE_SUCCESS',
  ADD_CERTIFICATE_FAILED = 'ADD_CERTIFICATE_FAILED',

  GET_CERTIFICATE = 'GET_CERTIFICATE',
  GET_CERTIFICATE_SUCCESS = 'GET_CERTIFICATE_SUCCESS',
  GET_CERTIFICATE_FAILED = 'GET_CERTIFICATE_FAILED',

  UPDATE_CERTIFICATE = 'UPDATE_CERTIFICATE',
  UPDATE_CERTIFICATE_SUCCESS = 'UPDATE_CERTIFICATE_SUCCESS',
  UPDATE_CERTIFICATE_FAILED = 'UPDATE_CERTIFICATE_FAILED',
  UPDATE_CERTIFICATE_ORDERING = 'UPDATE_CERTIFICATE_ORDERING',

  DELETE_CERTIFICATE = 'DELETE_CERTIFICATE',
  DELETE_CERTIFICATE_SUCCESS = 'DELETE_CERTIFICATE_SUCCESS',
  DELETE_CERTIFICATE_FAILED = 'DELETE_CERTIFICATE_FAILED',

  ADD_LANGUAGE = 'ADD_LANGUAGE',
  ADD_LANGUAGE_SUCCESS = 'ADD_LANGUAGE_SUCCESS',
  ADD_LANGUAGE_FAILED = 'ADD_LANGUAGE_FAILED',

  GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST',

  GET_LANGUAGE = 'GET_LANGUAGE',
  GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS',
  GET_LANGUAGE_FAILED = 'GET_LANGUAGE_FAILED',

  UPDATE_LANGUAGE = 'UPDATE_LANGUAGE',
  UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS',
  UPDATE_LANGUAGE_FAILED = 'UPDATE_LANGUAGE_FAILED',
  UPDATE_LANGUAGE_ORDERING = 'UPDATE_LANGUAGE_ORDERING',

  DELETE_LANGUAGE = 'DELETE_LANGUAGE',
  DELETE_LANGUAGE_SUCCESS = 'DELETE_LANGUAGE_SUCCESS',
  DELETE_LANGUAGE_FAILED = 'DELETE_LANGUAGE_FAILED',

  ADD_VOLUNTEER = 'ADD_VOLUNTEER',
  ADD_VOLUNTEER_SUCCESS = 'ADD_VOLUNTEER_SUCCESS',
  ADD_VOLUNTEER_FAILED = 'ADD_VOLUNTEER_FAILED',

  GET_VOLUNTEER = 'GET_VOLUNTEER',
  GET_VOLUNTEER_SUCCESS = 'GET_VOLUNTEER_SUCCESS',
  GET_VOLUNTEER_FAILED = 'GET_VOLUNTEER_FAILED',

  UPDATE_VOLUNTEER = 'UPDATE_VOLUNTEER',
  UPDATE_VOLUNTEER_SUCCESS = 'UPDATE_VOLUNTEER_SUCCESS',
  UPDATE_VOLUNTEER_FAILED = 'UPDATE_VOLUNTEER_FAILED',
  UPDATE_VOLUNTEER_ORDERING = 'UPDATE_VOLUNTEER_ORDERING',

  DELETE_VOLUNTEER = 'DELETE_VOLUNTEER',
  DELETE_VOLUNTEER_SUCCESS = 'DELETE_VOLUNTEER_SUCCESS',
  DELETE_VOLUNTEER_FAILED = 'DELETE_VOLUNTEER_FAILED',

  GET_SKILL_LIST = 'GET_SKILL_LIST',

  SUBMIT_CONTACT_US = 'SUBMIT_CONTACT_US',

  FACEBOOK_LOGIN = 'FACEBOOK_LOGIN',

  LINKEDIN_LOGIN = 'LINKEDIN_LOGIN',

  GET_INTEREST_LIST = 'GET_INTEREST_LIST',
  ADD_INTEREST_LIST = 'ADD_INTEREST_LIST',
  DELETE_INTEREST_LIST = 'DELETE_INTEREST_LIST',

  GET_REFERRAL_DETAIL = 'GET_REFERRAL_DETAIL',
  GET_REFERRAL_DETAIL_SUCCESS = 'GET_REFERRAL_DETAIL_SUCCESS',
  GET_REFERRAL_DETAIL_FAILED = 'GET_REFERRAL_DETAIL_FAILED',

  ADD_CLICKS = 'ADD_CLICKS',
}

export interface Login {
  type: actionTypes.LOGIN;
  payload: LoginPayload;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface LoginSuccess {
  type: actionTypes.LOGIN_SUCCESS;
  data: LoginData;
}

export interface LoginFailed {
  type: actionTypes.LOGIN_FAILED;
  error: Error;
}

export interface GetProfile {
  type: actionTypes.GET_PROFILE;
  payload: GetProfilePayload;
  callbacks?: ReduxUnfoldSaga.CallbacksType;
}

export interface GetProfileSuccess {
  type: actionTypes.GET_PROFILE_SUCCESS;
  data: Profile;
}

export interface GetProfileFailed {
  type: actionTypes.GET_PROFILE_FAILED;
  error: Error;
}

export interface CheckVerifyEmail {
  type: actionTypes.CHECK_EMAIL_VERIFY;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface CheckVerifyEmailSuccess {
  type: actionTypes.CHECK_EMAIL_VERIFY_SUCCESS;
  data: CheckVerifyEmailPayload;
}

export interface CheckVerifyEmailFailed {
  type: actionTypes.CHECK_EMAIL_VERIFY_FAILED;
  error: Error;
}

export interface EditProfile {
  type: actionTypes.EDIT_PROFILE;
  payload: EditProfilePayload;
}

export interface EditProfileSuccess {
  type: actionTypes.EDIT_PROFILE_SUCCESS;
  data: Profile;
}

export interface EditProfileFailed {
  type: actionTypes.EDIT_PROFILE_FAILED;
  error: Error;
}

export interface BlockAccount {
  type: actionTypes.BLOCK_ACCOUNT;
  data: BlockAccountPayload;
}

export interface ChangePassword {
  type: actionTypes.CHANGE_PASSWORD;
  data: ChangePasswordPayload;
}

export interface ChangePasswordSuccess {
  type: actionTypes.CHANGE_PASSWORD_SUCCESS;
}

export interface ChangePasswordFailed {
  type: actionTypes.CHANGE_PASSWORD_FAILED;
  error: Error;
}

export interface ResetStore {
  type: actionTypes.RESET_STORE;
}

export interface Logout {
  type: actionTypes.LOGOUT;
}

export interface ForgotPassword {
  type: actionTypes.FORGOT_PASSWORD;
  payload: ForgotPasswordPayload;
  callbacks?: ReduxUnfoldSaga.CallbacksType;
}

export interface CreatePassword {
  type: actionTypes.CREATE_PASSWORD;
  payload: CreatePasswordPayload;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface Register {
  type: actionTypes.REGISTER;
  payload: RegisterPayload;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface RegisterSuccess {
  type: actionTypes.REGISTER_SUCCESS_ACTION;
  data: RegisterSuccessData;
}

export interface VerifyEmail {
  type: actionTypes.VERIFY_EMAIL;
  payload: VerifyEmailPayload;
}

export interface VerifyEmailSuccess {
  type: actionTypes.VERIFY_EMAIL_SUCCESS;
  data: VerifyEmailSuccessData;
}

export interface VerifyEmailFailed {
  type: actionTypes.VERIFY_EMAIL_FAILED;
  error: Error;
}

export interface ResendConfirmationEmail {
  type: actionTypes.RESEND_CONFIRMATION_EMAIL;
  payload: ResendConfirmationEmailPayload;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface GetOnboardingRequirement {
  type: actionTypes.GET_ONBOARDING_REQUIREMENT;
}

export interface GetOnboardingRequirementSuccess {
  type: actionTypes.GET_ONBOARDING_REQUIREMENT_SUCCESS;
  data: OnboardingRequirementData;
}

export interface GetOnboardingRequirementFailed {
  type: actionTypes.GET_ONBOARDING_REQUIREMENT_FAILED;
  error: Error;
}

export interface SaveCVInfo {
  type: actionTypes.SAVE_CV_INFO;
  data: CvInfo;
}

export interface SubmitOnboarding {
  type: actionTypes.SUBMIT_ONBOARDING;
  data: Profile['candidate'];
  callbacks?: ReduxUnfoldSaga.CallbacksType;
}

export interface SubmitOnboardingSuccess {
  type: actionTypes.SUBMIT_ONBOARDING_SUCCESS;
  data: SubmitOnboardingData;
}

export interface SubmitOnboardingFailed {
  type: actionTypes.SUBMIT_ONBOARDING_FAILED;
  error: Error;
}

export interface GetEducation {
  type: actionTypes.GET_EDUCATION;
}

export interface GetEducationSuccess {
  type: actionTypes.GET_EDUCATION_SUCCESS;
  data: ProfileEducation[];
}

export interface GetEducationFailed {
  type: actionTypes.GET_EDUCATION_FAILED;
  error: Error;
}

export interface AddEducation {
  type: actionTypes.ADD_EDUCATION;
  data: ProfileEducation;
}

export interface AddEducationSuccess {
  type: actionTypes.ADD_EDUCATION_SUCCESS;
  data: ProfileEducation;
}

export interface AddEducationFailed {
  type: actionTypes.ADD_EDUCATION_FAILED;
  error: Error;
}

export interface UpdateEducation {
  type: actionTypes.UPDATE_EDUCATION;
  data: ProfileEducation;
}

export interface UpdateEducationSuccess {
  type: actionTypes.UPDATE_EDUCATION_SUCCESS;
  data: ProfileEducation;
}

export interface UpdateEducationFailed {
  type: actionTypes.UPDATE_EDUCATION_FAILED;
  error: Error;
}

export interface UpdateEducationOrdering {
  type: actionTypes.UPDATE_EDUCATION_ORDERING;
  data: ProfileEducation[];
}

export interface RemoveEducation {
  type: actionTypes.REMOVE_EDUCATION;
  data: { id: number };
}

export interface RemoveEducationSuccess {
  type: actionTypes.REMOVE_EDUCATION_SUCCESS;
  data: { id: number };
}

export interface RemoveEducationFailed {
  type: actionTypes.REMOVE_EDUCATION_FAILED;
  error: Error;
}

export interface GetExperience {
  type: actionTypes.GET_EXPERIENCE;
}

export interface GetExperienceSuccess {
  type: actionTypes.GET_EXPERIENCE_SUCCESS;
  data: ProfileExperience[];
}

export interface GetExperienceFailed {
  type: actionTypes.GET_EXPERIENCE_FAILED;
  error: Error;
}

export interface AddExperience {
  type: actionTypes.ADD_EXPERIENCE;
  data: ProfileExperience;
  callback?: () => void;
}

export interface AddExperienceSuccess {
  type: actionTypes.ADD_EXPERIENCE_SUCCESS;
  data: ProfileExperience;
}

export interface AddExperienceFailed {
  type: actionTypes.ADD_EXPERIENCE_FAILED;
  error: Error;
}

export interface UpdateExperience {
  type: actionTypes.UPDATE_EXPERIENCE;
  data: ProfileExperience;
}

export interface UpdateExperienceSuccess {
  type: actionTypes.UPDATE_EXPERIENCE_SUCCESS;
  data: ProfileExperience;
}

export interface UpdateExperienceFailed {
  type: actionTypes.UPDATE_EXPERIENCE_FAILED;
  error: Error;
}

export interface UpdateExperienceOrdering {
  type: actionTypes.UPDATE_EXPERIENCE_ORDERING;
  data: ProfileExperience[];
}

export interface RemoveExperience {
  type: actionTypes.REMOVE_EXPERIENCE;
  data: { id: number };
}

export interface RemoveExperienceSuccess {
  type: actionTypes.REMOVE_EXPERIENCE_SUCCESS;
  data: { id: number };
}

export interface RemoveExperienceFailed {
  type: actionTypes.REMOVE_EXPERIENCE_FAILED;
  error: Error;
}

export interface UpdateOrdering {
  type: actionTypes.UPDATE_ORDERING;
  payload: UpdateOrderingPayload;
}

export interface UpdateOrderingSuccess {
  type: actionTypes.UPDATE_ORDERING_SUCCESS;
  data: UpdateOrderingData;
}

export interface UpdateOrderingFailed {
  type: actionTypes.UPDATE_ORDERING_FAILED;
  error: Error;
}

export interface GetMajor {
  type: actionTypes.GET_MAJOR;
}

export interface GetMajorSuccess {
  type: actionTypes.GET_MAJOR_SUCCESS;
  data: MajorData;
}

export interface GetMajorFailed {
  type: actionTypes.GET_MAJOR_FAILED;
  error: Error;
}

export interface SetCvTransactionId {
  type: actionTypes.SET_CV_TRANSACTION_ID;
  data: string;
}

export interface CancelCvTransaction {
  type: actionTypes.CANCEL_CV_TRANSACTION;
  data: { id: string };
}

export interface CancelCvTransactionSuccess {
  type: actionTypes.CANCEL_CV_TRANSACTION_SUCCESS;
}

export interface CancelCvTransactionFailed {
  type: actionTypes.CANCEL_CV_TRANSACTION_FAILED;
}

export interface SetCvSelected {
  type: actionTypes.SET_CV_SELECTED;
  data: CvTemplateData;
}

export interface GetCvTemplateList {
  type: actionTypes.GET_LIST_CV_TEMPLATE;
  payload?: FilterCvTemplatePayload;
  callbacks?: ReduxUnfoldSaga.CallbacksType;
}

export interface GetCvTemplateListSuccess {
  type: actionTypes.GET_LIST_CV_TEMPLATE_SUCCESS;
  data: CvTemplateData[];
}

export interface GetCvTemplateListFailed {
  type: actionTypes.GET_LIST_CV_TEMPLATE_FAILED;
  error: Error;
}

export interface GetCvTransactionList {
  type: actionTypes.GET_CV_TRANSACTION_LIST;
}

export interface GetCvTransactionListSuccess {
  type: actionTypes.GET_CV_TRANSACTION_LIST_SUCCESS;
  data: CvTransactionData[];
}

export interface GetCvTransactionListFailed {
  type: actionTypes.GET_CV_TRANSACTION_LIST_FAILED;
  error: Error;
}

export interface SetCurrentCvColor {
  type: actionTypes.SET_CURRENT_CV_COLOR;
  data: string;
}

export interface SetCurrentCvFont {
  type: actionTypes.SET_CURRENT_CV_FONT;
  data: string;
}

export interface SetCurrentCvFontSize {
  type: actionTypes.SET_CURRENT_CV_FONT_SIZE;
  data: number;
}

export interface SetShowAvatar {
  type: actionTypes.SET_SHOW_AVATAR;
  data: boolean;
}

export interface SetShowProficiency {
  type: actionTypes.SET_SHOW_PROFICIENCY;
  data: boolean;
}

export interface GetMyCVTemplateList {
  type: actionTypes.GET_MY_CV_TEMPLATE_LIST;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface GetMyCVTemplateListSuccess {
  type: actionTypes.GET_MY_CV_TEMPLATE_LIST_SUCCESS;
  data: MyCVTemplateData[];
}

export interface GetMyCVTemplateListFailed {
  type: actionTypes.GET_MY_CV_TEMPLATE_LIST_FAILED;
  error: Error;
}

export interface CreateMyCVTemplate {
  type: actionTypes.CREATE_MY_CV_TEMPLATE;
  payload: CreateMyCVTemplatePayload;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface CreateMyCVTemplateSuccess {
  type: actionTypes.CREATE_MY_CV_TEMPLATE_SUCCESS;
  data: CreateMyCVTemplateData;
}

export interface CreateMyCVTemplateFailed {
  type: actionTypes.CREATE_MY_CV_TEMPLATE_FAILED;
  error: Error;
}

export interface UpdateMyCVTemplate {
  type: actionTypes.UPDATE_MY_CV_TEMPLATE;
  payload: MyCVTemplateData;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface UpdateMyCVTemplateSuccess {
  type: actionTypes.UPDATE_MY_CV_TEMPLATE_SUCCESS;
  data: UpdateMyCVTemplateData;
}

export interface UpdateMyCVTemplateFailed {
  type: actionTypes.UPDATE_MY_CV_TEMPLATE_FAILED;
  error: Error;
}

export interface DeleteMyCVTemplate {
  type: actionTypes.DELETE_MY_CV_TEMPLATE;
  payload: MyCVTemplateData;
}

export interface DeleteMyCVTemplateSuccess {
  type: actionTypes.DELETE_MY_CV_TEMPLATE_SUCCESS;
  data: DeleteMyCVTemplateData;
}

export interface DeleteMyCVTemplateFailed {
  type: actionTypes.DELETE_MY_CV_TEMPLATE_FAILED;
  error: Error;
}

export interface GetTerm {
  type: actionTypes.GET_TERM;
  payload: { lang: string };
}

export interface GetTermSuccess {
  type: actionTypes.GET_TERM_SUCCESS;
  data: { urbancv_tnc: string };
}

export interface GetTermFailed {
  type: actionTypes.GET_TERM_FAILED;
  error: Error;
}

export interface GetPolicy {
  type: actionTypes.GET_POLICY;
  payload: { lang: string };
}

export interface GetPolicySuccess {
  type: actionTypes.GET_POLICY_SUCCESS;
  data: { privacy_policy_urbancv: string };
}

export interface GetPolicyFailed {
  type: actionTypes.GET_POLICY_FAILED;
  error: Error;
}

export interface GetSampleCvList {
  type: actionTypes.GET_SAMPLE_CV_LIST;
  payload: SampleCVPayload;
}

export interface GetSampleCvListSuccess {
  type: actionTypes.GET_SAMPLE_CV_LIST_SUCCESS;
  data: SampleCVData[];
}

export interface GetSampleCvListFailed {
  type: actionTypes.GET_SAMPLE_CV_LIST_FAILED;
  error: Error;
}

export interface GetCvCategoryList {
  type: actionTypes.GET_CV_CATEGORY_LIST;
}

export interface GetCvCategoryListSuccess {
  type: actionTypes.GET_CV_CATEGORY_LIST_SUCCESS;
  data: CvCategoryData[];
}

export interface GetCvCategoryListFailed {
  type: actionTypes.GET_CV_CATEGORY_LIST_FAILED;
  error: Error;
}

export interface GetCvDetail {
  type: actionTypes.GET_CV_DETAIL_PAGE;
  payload: SampleCVDetailPayload;
}
export interface GetCvDetailSuccess {
  type: actionTypes.GET_CV_DETAIL_PAGE_SUCCESS;
  data: CvTemplateData;
}
export interface GetCvDetailFailed {
  type: actionTypes.GET_CV_DETAIL_PAGE_FAILED;
  error: Error;
}

export interface GetOccupationList {
  type: actionTypes.GET_OCCUPATION_LIST;
}

export interface GetOccupationListSuccess {
  type: actionTypes.GET_OCCUPATION_LIST_SUCCESS;
  data: OccupationData[];
}

export interface GetOccupationListFailed {
  type: actionTypes.GET_OCCUPATION_LIST_FAILED;
  error: Error;
}

export interface GetOccupationDetails {
  type: actionTypes.GET_OCCUPATION_DETAILS;
  data: { occupationId: number };
}

export interface GetOccupationDetailsSuccess {
  type: actionTypes.GET_OCCUPATION_DETAILS_SUCCESS;
  data: OccupationDetailsData;
}

export interface GetOccupationDetailsFailed {
  type: actionTypes.GET_OCCUPATION_DETAILS_FAILED;
  error: Error;
}

export interface ResetOccupationDetails {
  type: actionTypes.RESET_OCCUPATION_DETAILS;
}

export interface GetSharedCVDetails {
  type: actionTypes.GET_SHARED_CV_DETAIL;
  payload: SharedCVDetailsPayload;
}

export interface GetSharedCVDetailsSuccess {
  type: actionTypes.GET_SHARED_CV_DETAIL_SUCCESS;
  data: CvInfo;
}

export interface GetSharedCVDetailsFailed {
  type: actionTypes.GET_SHARED_CV_DETAIL_FAILED;
  error: Error;
}

export interface GetJobScopeList {
  type: actionTypes.GET_JOB_SCOPE_LIST;
  data: { keyword: string };
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface GetDegreeList {
  type: actionTypes.GET_DEGREE_LIST;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface GetSchoolList {
  type: actionTypes.GET_SCHOOL_LIST;
  data: { keyword: string };
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface GetCompanyList {
  type: actionTypes.GET_COMPANY_LIST;
  data: { mode: string; keyword: string };
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface GetJobTypeList {
  type: actionTypes.GET_JOB_TYPE_LIST;
  data: { keyword: string };
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface AddReference {
  type: actionTypes.ADD_REFERENCE;
  data: ProfileReference;
}

export interface AddReferenceSuccess {
  type: actionTypes.ADD_REFERENCE_SUCCESS;
  data: ProfileReference;
}

export interface AddReferenceFailed {
  type: actionTypes.ADD_REFERENCE_FAILED;
  error: Error;
}

export interface GetReference {
  type: actionTypes.GET_REFERENCE;
}

export interface GetReferenceSuccess {
  type: actionTypes.GET_REFERENCE_SUCCESS;
  data: ProfileReference[];
}

export interface GetReferenceFailed {
  type: actionTypes.GET_REFERENCE_FAILED;
  error: Error;
}

export interface UpdateReference {
  type: actionTypes.UPDATE_REFERENCE;
  data: ProfileReference;
}

export interface UpdateReferenceSuccess {
  type: actionTypes.UPDATE_REFERENCE_SUCCESS;
  data: ProfileReference;
}

export interface UpdateReferenceFailed {
  type: actionTypes.UPDATE_REFERENCE_FAILED;
  error: Error;
}

export interface UpdateReferenceOrdering {
  type: actionTypes.UPDATE_REFERENCE_ORDERING;
  data: ProfileReference[];
}

export interface DeleteReference {
  type: actionTypes.DELETE_REFERENCE;
  data: { id: number };
}

export interface DeleteReferenceSuccess {
  type: actionTypes.DELETE_REFERENCE_SUCCESS;
  data: { id: number };
}

export interface DeleteReferenceFailed {
  type: actionTypes.DELETE_REFERENCE_FAILED;
  error: Error;
}

export interface AddCertificate {
  type: actionTypes.ADD_CERTIFICATE;
  data: ProfileCertificate;
  callback?: () => void;
}

export interface AddCertificateSuccess {
  type: actionTypes.ADD_CERTIFICATE_SUCCESS;
  data: ProfileCertificate;
}

export interface AddCertificateFailed {
  type: actionTypes.ADD_CERTIFICATE_FAILED;
  error: Error;
}

export interface GetCertificate {
  type: actionTypes.GET_CERTIFICATE;
}

export interface GetCertificateSuccess {
  type: actionTypes.GET_CERTIFICATE_SUCCESS;
  data: ProfileCertificate[];
}

export interface GetCertificateFailed {
  type: actionTypes.GET_CERTIFICATE_FAILED;
  error: Error;
}

export interface UpdateCertificate {
  type: actionTypes.UPDATE_CERTIFICATE;
  data: ProfileCertificate;
}

export interface UpdateCertificateSuccess {
  type: actionTypes.UPDATE_CERTIFICATE_SUCCESS;
  data: ProfileCertificate;
}

export interface UpdateCertificateFailed {
  type: actionTypes.UPDATE_CERTIFICATE_FAILED;
  error: Error;
}

export interface UpdateCertificateOrdering {
  type: actionTypes.UPDATE_CERTIFICATE_ORDERING;
  data: ProfileCertificate[];
}

export interface DeleteCertificate {
  type: actionTypes.DELETE_CERTIFICATE;
  data: { id: number };
}

export interface DeleteCertificateSuccess {
  type: actionTypes.DELETE_CERTIFICATE_SUCCESS;
  data: { id: number };
}

export interface DeleteCertificateFailed {
  type: actionTypes.DELETE_CERTIFICATE_FAILED;
  error: Error;
}

export interface AddLanguage {
  type: actionTypes.ADD_LANGUAGE;
  data: ProfileLanguage;
}

export interface AddLanguageSuccess {
  type: actionTypes.ADD_LANGUAGE_SUCCESS;
  data: ProfileLanguage;
}

export interface AddLanguageFailed {
  type: actionTypes.ADD_LANGUAGE_FAILED;
  error: Error;
}

export interface GetLanguageList {
  type: actionTypes.GET_LANGUAGE_LIST;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface GetLanguage {
  type: actionTypes.GET_LANGUAGE;
}
export interface GetLanguageSuccess {
  type: actionTypes.GET_LANGUAGE_SUCCESS;
  data: ProfileLanguage[];
}

export interface GetLanguageFailed {
  type: actionTypes.GET_LANGUAGE_FAILED;
  error: Error;
}

export interface UpdateLanguage {
  type: actionTypes.UPDATE_LANGUAGE;
  data: ProfileLanguage;
}

export interface UpdateLanguageSuccess {
  type: actionTypes.UPDATE_LANGUAGE_SUCCESS;
  data: ProfileLanguage;
}

export interface UpdateLanguageFailed {
  type: actionTypes.UPDATE_LANGUAGE_FAILED;
  error: Error;
}

export interface UpdateLanguageOrdering {
  type: actionTypes.UPDATE_LANGUAGE_ORDERING;
  data: ProfileLanguage[];
}

export interface DeleteLanguage {
  type: actionTypes.DELETE_LANGUAGE;
  data: { id: number };
}

export interface DeleteLanguageSuccess {
  type: actionTypes.DELETE_LANGUAGE_SUCCESS;
  data: { id: number };
}

export interface DeleteLanguageFailed {
  type: actionTypes.DELETE_LANGUAGE_FAILED;
  error: Error;
}

export interface AddVolunteer {
  type: actionTypes.ADD_VOLUNTEER;
  data: ProfileVolunteer;
  callback?: () => void;
}

export interface AddVolunteerSuccess {
  type: actionTypes.ADD_VOLUNTEER_SUCCESS;
  data: ProfileVolunteer;
}

export interface AddVolunteerFailed {
  type: actionTypes.ADD_VOLUNTEER_FAILED;
  error: Error;
}

export interface GetVolunteer {
  type: actionTypes.GET_VOLUNTEER;
}

export interface GetVolunteerSuccess {
  type: actionTypes.GET_VOLUNTEER_SUCCESS;
  data: ProfileVolunteer[];
}

export interface GetVolunteerFailed {
  type: actionTypes.GET_VOLUNTEER_FAILED;
  error: Error;
}

export interface UpdateVolunteer {
  type: actionTypes.UPDATE_VOLUNTEER;
  data: ProfileVolunteer;
}

export interface UpdateVolunteerSuccess {
  type: actionTypes.UPDATE_VOLUNTEER_SUCCESS;
  data: ProfileVolunteer;
}

export interface UpdateVolunteerFailed {
  type: actionTypes.UPDATE_VOLUNTEER_FAILED;
  error: Error;
}

export interface UpdateVolunteerOrdering {
  type: actionTypes.UPDATE_VOLUNTEER_ORDERING;
  data: ProfileVolunteer[];
}

export interface DeleteVolunteer {
  type: actionTypes.DELETE_VOLUNTEER;
  data: { id: number };
}

export interface DeleteVolunteerSuccess {
  type: actionTypes.DELETE_VOLUNTEER_SUCCESS;
  data: { id: number };
}

export interface DeleteVolunteerFailed {
  type: actionTypes.DELETE_VOLUNTEER_FAILED;
  error: Error;
}

export interface GetSkillList {
  type: actionTypes.GET_SKILL_LIST;
  data: { keyword: string };
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface SubmitContactUs {
  type: actionTypes.SUBMIT_CONTACT_US;
  payload: ContactUsPayload;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface FacebookLogin {
  type: actionTypes.FACEBOOK_LOGIN;
  payload: FacebookLoginPayload;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface LinkedInLogin {
  type: actionTypes.LINKEDIN_LOGIN;
  payload: LinkedInLoginPayload;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface GetInterest {
  type: actionTypes.GET_INTEREST_LIST;
  callbacks: ReduxUnfoldSaga.CallbacksType;
}

export interface AddInterest {
  type: actionTypes.ADD_INTEREST_LIST;
  data: string[];
}

export interface DeleteInterest {
  type: actionTypes.DELETE_INTEREST_LIST;
  idx: number;
}

export interface GetReferralDetail {
  type: actionTypes.GET_REFERRAL_DETAIL;
}

export interface GetReferralDetailSuccess {
  type: actionTypes.GET_REFERRAL_DETAIL_SUCCESS;
  data: ReferralData;
}

export interface GetReferralDetailFailed {
  type: actionTypes.GET_REFERRAL_DETAIL_FAILED;
  error: Error;
}

export interface AddClicks {
  type: actionTypes.ADD_CLICKS;
  payload: AddClicksPayload;
}

export type Action =
  | Login
  | LoginSuccess
  | LoginFailed
  | ResetStore
  | ForgotPassword
  | CreatePassword
  | Register
  | RegisterSuccess
  | VerifyEmail
  | VerifyEmailSuccess
  | VerifyEmailFailed
  | ResendConfirmationEmail
  | GetOnboardingRequirement
  | GetOnboardingRequirementSuccess
  | GetOnboardingRequirementFailed
  | SaveCVInfo
  | SubmitOnboarding
  | SubmitOnboardingSuccess
  | SubmitOnboardingFailed
  | GetMajor
  | GetMajorSuccess
  | GetMajorFailed
  | SetCurrentCvColor
  | SetCurrentCvFont
  | SetCurrentCvFontSize
  | SetShowAvatar
  | SetShowProficiency
  | SetCvSelected
  | GetMyCVTemplateList
  | GetMyCVTemplateListSuccess
  | GetMyCVTemplateListFailed
  | CreateMyCVTemplate
  | CreateMyCVTemplateSuccess
  | CreateMyCVTemplateFailed
  | UpdateMyCVTemplate
  | UpdateMyCVTemplateSuccess
  | UpdateMyCVTemplateFailed
  | DeleteMyCVTemplate
  | DeleteMyCVTemplateSuccess
  | DeleteMyCVTemplateFailed
  | GetProfile
  | GetProfileSuccess
  | CheckVerifyEmail
  | CheckVerifyEmailFailed
  | CheckVerifyEmailSuccess
  | GetProfileFailed
  | EditProfile
  | EditProfileSuccess
  | EditProfileFailed
  | GetEducation
  | GetEducationSuccess
  | GetEducationFailed
  | AddEducation
  | AddEducationSuccess
  | AddEducationFailed
  | UpdateEducation
  | UpdateEducationSuccess
  | UpdateEducationFailed
  | UpdateEducationOrdering
  | RemoveEducation
  | RemoveEducationSuccess
  | RemoveEducationFailed
  | GetExperience
  | GetExperienceSuccess
  | GetExperienceFailed
  | AddExperience
  | AddExperienceSuccess
  | AddExperienceFailed
  | UpdateExperience
  | UpdateExperienceSuccess
  | UpdateExperienceFailed
  | UpdateExperienceOrdering
  | RemoveExperience
  | RemoveExperienceSuccess
  | RemoveExperienceFailed
  | UpdateOrdering
  | UpdateOrderingSuccess
  | UpdateOrderingFailed
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordFailed
  | GetCvTemplateList
  | GetCvTemplateListSuccess
  | GetCvTemplateListFailed
  | GetCvTransactionList
  | GetCvTransactionListSuccess
  | GetCvTransactionListFailed
  | SetCvTransactionId
  | CancelCvTransaction
  | CancelCvTransactionSuccess
  | CancelCvTransactionFailed
  | GetTerm
  | GetTermSuccess
  | GetTermFailed
  | GetPolicy
  | GetPolicySuccess
  | GetPolicyFailed
  | GetSampleCvList
  | GetSampleCvListSuccess
  | GetSampleCvListFailed
  | GetCvCategoryList
  | GetCvCategoryListSuccess
  | GetCvCategoryListFailed
  | GetCvDetail
  | GetCvDetailSuccess
  | GetCvDetailFailed
  | GetOccupationList
  | GetOccupationListSuccess
  | GetOccupationListFailed
  | GetOccupationDetails
  | GetOccupationDetailsSuccess
  | GetOccupationDetailsFailed
  | GetSharedCVDetails
  | GetSharedCVDetailsSuccess
  | GetSharedCVDetailsFailed
  | GetJobScopeList
  | GetDegreeList
  | GetSchoolList
  | GetJobTypeList
  | GetCompanyList
  | AddReference
  | AddReferenceSuccess
  | AddReferenceFailed
  | GetReference
  | GetReferenceSuccess
  | GetReferenceFailed
  | UpdateReference
  | UpdateReferenceSuccess
  | UpdateReferenceFailed
  | UpdateReferenceOrdering
  | DeleteReference
  | DeleteReferenceSuccess
  | DeleteReferenceFailed
  | GetSkillList
  | FacebookLogin
  | LinkedInLogin
  | GetInterest
  | AddInterest
  | DeleteInterest
  | AddCertificate
  | AddCertificateSuccess
  | AddCertificateFailed
  | GetCertificate
  | GetCertificateFailed
  | GetCertificateSuccess
  | UpdateCertificate
  | UpdateCertificateSuccess
  | UpdateCertificateFailed
  | UpdateCertificateOrdering
  | DeleteCertificate
  | DeleteCertificateSuccess
  | DeleteCertificateFailed
  | AddLanguage
  | AddLanguageSuccess
  | AddLanguageFailed
  | GetLanguageList
  | GetLanguage
  | GetLanguageSuccess
  | GetLanguageFailed
  | UpdateLanguage
  | UpdateLanguageSuccess
  | UpdateLanguageFailed
  | UpdateLanguageOrdering
  | DeleteLanguage
  | DeleteLanguageSuccess
  | DeleteLanguageFailed
  | AddVolunteer
  | AddVolunteerSuccess
  | AddVolunteerFailed
  | GetVolunteer
  | GetVolunteerFailed
  | GetVolunteerSuccess
  | UpdateVolunteer
  | UpdateVolunteerSuccess
  | UpdateVolunteerFailed
  | UpdateVolunteerOrdering
  | DeleteVolunteer
  | DeleteVolunteerSuccess
  | DeleteVolunteerFailed
  | GetReferralDetail
  | GetReferralDetailSuccess
  | GetReferralDetailFailed
  | AddClicks;
