import { CvInfo } from 'components/organisms/Onboarding/interface';
import {
  AddClicksPayload,
  BlockAccountPayload,
  ChangePasswordPayload,
  ContactUsPayload,
  CreateMyCVTemplateData,
  CreateMyCVTemplatePayload,
  CreatePasswordPayload,
  CvTemplateData,
  CvTransactionData,
  DeleteMyCVTemplateData,
  EditProfilePayload,
  Error,
  FacebookLoginPayload,
  FilterCvTemplatePayload,
  ForgotPasswordPayload,
  GetProfilePayload,
  LinkedInLoginPayload,
  LoginData,
  LoginPayload,
  MajorData,
  MyCVTemplateData,
  OnboardingRequirementData,
  Profile,
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
  ProfileReference,
  ProfileVolunteer,
  ReferralData,
  RegisterPayload,
  RegisterSuccessData,
  ResendConfirmationEmailPayload,
  SampleCVDetailPayload,
  SampleCVPayload,
  SharedCVDetailsPayload,
  SubmitOnboardingData,
  UpdateMyCVTemplateData,
  UpdateOrderingData,
  UpdateOrderingPayload,
  VerifyEmailPayload,
  VerifyEmailSuccessData,
  actionTypes,
} from './interfaces';
import * as actionIs from './interfaces/actions.interfaces';

export function login(payload: LoginPayload, callbacks: ReduxUnfoldSaga.CallbacksType): actionIs.Login {
  return { type: actionTypes.LOGIN, payload, callbacks };
}

export function loginSuccess(data: LoginData): actionIs.LoginSuccess {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    data,
  };
}

export function loginFailed(error: Error): actionIs.LoginFailed {
  return {
    type: actionTypes.LOGIN_FAILED,
    error,
  };
}

export function checkVerifyEmail(callbacks: ReduxUnfoldSaga.CallbacksType): actionIs.CheckVerifyEmail {
  return { type: actionTypes.CHECK_EMAIL_VERIFY, callbacks };
}

export function getProfile(payload: GetProfilePayload, callbacks?: ReduxUnfoldSaga.CallbacksType): actionIs.GetProfile {
  return { type: actionTypes.GET_PROFILE, payload, callbacks };
}

export function getProfileSuccess(data: Profile): actionIs.GetProfileSuccess {
  return {
    type: actionTypes.GET_PROFILE_SUCCESS,
    data,
  };
}

export function getProfileFailed(error: Error): actionIs.GetProfileFailed {
  return {
    type: actionTypes.GET_PROFILE_FAILED,
    error,
  };
}

export function setProfile(payload: EditProfilePayload): actionIs.EditProfile {
  return {
    type: actionTypes.EDIT_PROFILE,
    payload,
  };
}

export function setProfileSuccess(data: Profile): actionIs.EditProfileSuccess {
  return {
    type: actionTypes.EDIT_PROFILE_SUCCESS,
    data,
  };
}

export function setProfileFailed(error: Error): actionIs.EditProfileFailed {
  return {
    type: actionTypes.EDIT_PROFILE_FAILED,
    error,
  };
}

export function blockAccount(data: BlockAccountPayload): actionIs.BlockAccount {
  return {
    type: actionTypes.BLOCK_ACCOUNT,
    data,
  };
}

export function changePassword(data: ChangePasswordPayload): actionIs.ChangePassword {
  return {
    type: actionTypes.CHANGE_PASSWORD,
    data,
  };
}

export function changePasswordSuccess(): actionIs.ChangePasswordSuccess {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
  };
}

export function changePasswordFailed(error: Error): actionIs.ChangePasswordFailed {
  return {
    type: actionTypes.CHANGE_PASSWORD_FAILED,
    error,
  };
}

export function resetStore() {
  return { type: actionTypes.RESET_STORE };
}

export function logout() {
  return { type: actionTypes.LOGOUT };
}

export function forgotPassword(
  payload: ForgotPasswordPayload,
  callbacks?: ReduxUnfoldSaga.CallbacksType,
): actionIs.ForgotPassword {
  return { type: actionTypes.FORGOT_PASSWORD, payload: { ...payload, source: 4 }, callbacks };
}

export function createPassword(
  payload: CreatePasswordPayload,
  callbacks: ReduxUnfoldSaga.CallbacksType,
): actionIs.CreatePassword {
  return { type: actionTypes.CREATE_PASSWORD, payload, callbacks };
}

export function register(payload: RegisterPayload, callbacks: ReduxUnfoldSaga.CallbacksType): actionIs.Register {
  return { type: actionTypes.REGISTER, payload, callbacks };
}

export function registerSuccessAction(data: RegisterSuccessData): actionIs.RegisterSuccess {
  return { type: actionTypes.REGISTER_SUCCESS_ACTION, data };
}

export function verifyEmail(payload: VerifyEmailPayload): actionIs.VerifyEmail {
  return { type: actionTypes.VERIFY_EMAIL, payload };
}

export function verifyEmailSuccess(data: VerifyEmailSuccessData): actionIs.VerifyEmailSuccess {
  return {
    type: actionTypes.VERIFY_EMAIL_SUCCESS,
    data,
  };
}

export function verifyEmailFailed(error: Error): actionIs.VerifyEmailFailed {
  return {
    type: actionTypes.VERIFY_EMAIL_FAILED,
    error,
  };
}

export function resendConfirmationEmail(
  payload: ResendConfirmationEmailPayload,
  callbacks: ReduxUnfoldSaga.CallbacksType,
): actionIs.ResendConfirmationEmail {
  return {
    type: actionTypes.RESEND_CONFIRMATION_EMAIL,
    payload,
    callbacks,
  };
}

export function getOnboardingRequirement(): actionIs.GetOnboardingRequirement {
  return {
    type: actionTypes.GET_ONBOARDING_REQUIREMENT,
  };
}

export function getOnboardingRequirementSuccess(
  data: OnboardingRequirementData,
): actionIs.GetOnboardingRequirementSuccess {
  return {
    type: actionTypes.GET_ONBOARDING_REQUIREMENT_SUCCESS,
    data,
  };
}

export function getOnboardingRequirementFailed(error: Error): actionIs.GetOnboardingRequirementFailed {
  return {
    type: actionTypes.GET_ONBOARDING_REQUIREMENT_FAILED,
    error,
  };
}

export function saveCVInfo(data: CvInfo): actionIs.SaveCVInfo {
  return {
    type: actionTypes.SAVE_CV_INFO,
    data,
  };
}

export function submitOnboarding(
  data: Profile['candidate'],
  callbacks?: ReduxUnfoldSaga.CallbacksType,
): actionIs.SubmitOnboarding {
  return {
    type: actionTypes.SUBMIT_ONBOARDING,
    data,
    callbacks,
  };
}

export function submitOnboardingSuccess(data: SubmitOnboardingData): actionIs.SubmitOnboardingSuccess {
  return {
    type: actionTypes.SUBMIT_ONBOARDING_SUCCESS,
    data,
  };
}

export function submitOnboardingFailed(error: Error): actionIs.SubmitOnboardingFailed {
  return {
    type: actionTypes.SUBMIT_ONBOARDING_FAILED,
    error,
  };
}

export function getEducation(): actionIs.GetEducation {
  return {
    type: actionTypes.GET_EDUCATION,
  };
}

export function getEducationSuccess(data: ProfileEducation[]): actionIs.GetEducationSuccess {
  return {
    type: actionTypes.GET_EDUCATION_SUCCESS,
    data,
  };
}

export function getEducationFailed(error: Error): actionIs.GetEducationFailed {
  return {
    type: actionTypes.GET_EDUCATION_FAILED,
    error,
  };
}

export function addEducation(data: ProfileEducation): actionIs.AddEducation {
  return {
    type: actionTypes.ADD_EDUCATION,
    data,
  };
}

export function addEducationSuccess(data: ProfileEducation): actionIs.AddEducationSuccess {
  return {
    type: actionTypes.ADD_EDUCATION_SUCCESS,
    data,
  };
}

export function addEducationFailed(error: Error): actionIs.AddEducationFailed {
  return {
    type: actionTypes.ADD_EDUCATION_FAILED,
    error,
  };
}

export function updateEducation(data: ProfileEducation): actionIs.UpdateEducation {
  return {
    type: actionTypes.UPDATE_EDUCATION,
    data,
  };
}

export function updateEducationSuccess(data: ProfileEducation): actionIs.UpdateEducationSuccess {
  return {
    type: actionTypes.UPDATE_EDUCATION_SUCCESS,
    data,
  };
}

export function updateEducationFailed(error: Error): actionIs.UpdateEducationFailed {
  return {
    type: actionTypes.UPDATE_EDUCATION_FAILED,
    error,
  };
}

export function updateEducationOrdering(data: ProfileEducation[]): actionIs.UpdateEducationOrdering {
  return {
    type: actionTypes.UPDATE_EDUCATION_ORDERING,
    data,
  };
}

export function removeEducation(data: { id: number }): actionIs.RemoveEducation {
  return {
    type: actionTypes.REMOVE_EDUCATION,
    data,
  };
}

export function removeEducationSuccess(data: { id: number }): actionIs.RemoveEducationSuccess {
  return {
    type: actionTypes.REMOVE_EDUCATION_SUCCESS,
    data,
  };
}

export function removeEducationFailed(error: Error): actionIs.RemoveEducationFailed {
  return {
    type: actionTypes.REMOVE_EDUCATION_FAILED,
    error,
  };
}

export function getExperience(): actionIs.GetExperience {
  return {
    type: actionTypes.GET_EXPERIENCE,
  };
}

export function getExperienceSuccess(data: ProfileExperience[]): actionIs.GetExperienceSuccess {
  return {
    type: actionTypes.GET_EXPERIENCE_SUCCESS,
    data,
  };
}

export function getExperienceFailed(error: Error): actionIs.GetExperienceFailed {
  return {
    type: actionTypes.GET_EXPERIENCE_FAILED,
    error,
  };
}

export function addExperience(data: ProfileExperience, callback?: () => void): actionIs.AddExperience {
  return {
    type: actionTypes.ADD_EXPERIENCE,
    data,
    callback,
  };
}

export function addExperienceSuccess(data: ProfileExperience): actionIs.AddExperienceSuccess {
  return {
    type: actionTypes.ADD_EXPERIENCE_SUCCESS,
    data,
  };
}

export function addExperienceFailed(error: Error): actionIs.AddExperienceFailed {
  return {
    type: actionTypes.ADD_EXPERIENCE_FAILED,
    error,
  };
}

export function updateExperience(data: ProfileExperience): actionIs.UpdateExperience {
  return {
    type: actionTypes.UPDATE_EXPERIENCE,
    data,
  };
}

export function updateExperienceSuccess(data: ProfileExperience): actionIs.UpdateExperienceSuccess {
  return {
    type: actionTypes.UPDATE_EXPERIENCE_SUCCESS,
    data,
  };
}

export function updateExperienceFailed(error: Error): actionIs.UpdateExperienceFailed {
  return {
    type: actionTypes.UPDATE_EXPERIENCE_FAILED,
    error,
  };
}

export function updateExperienceOrdering(data: ProfileExperience[]): actionIs.UpdateExperienceOrdering {
  return {
    type: actionTypes.UPDATE_EXPERIENCE_ORDERING,
    data,
  };
}

export function removeExperience(data: { id: number }): actionIs.RemoveExperience {
  return {
    type: actionTypes.REMOVE_EXPERIENCE,
    data,
  };
}

export function removeExperienceSuccess(data: { id: number }): actionIs.RemoveExperienceSuccess {
  return {
    type: actionTypes.REMOVE_EXPERIENCE_SUCCESS,
    data,
  };
}

export function removeExperienceFailed(error: Error): actionIs.RemoveExperienceFailed {
  return {
    type: actionTypes.REMOVE_EXPERIENCE_FAILED,
    error,
  };
}

export function updateOrdering(payload: UpdateOrderingPayload): actionIs.UpdateOrdering {
  return {
    type: actionTypes.UPDATE_ORDERING,
    payload,
  };
}

export function updateOrderingSuccess(data: UpdateOrderingData): actionIs.UpdateOrderingSuccess {
  return {
    type: actionTypes.UPDATE_ORDERING_SUCCESS,
    data,
  };
}

export function updateOrderingFailed(error: Error): actionIs.UpdateOrderingFailed {
  return {
    type: actionTypes.UPDATE_ORDERING_FAILED,
    error,
  };
}

export function getMajor(): actionIs.GetMajor {
  return {
    type: actionTypes.GET_MAJOR,
  };
}

export function getMajorSuccess(data: MajorData): actionIs.GetMajorSuccess {
  return {
    type: actionTypes.GET_MAJOR_SUCCESS,
    data,
  };
}

export function getMajorFailed(error: Error): actionIs.GetMajorFailed {
  return {
    type: actionTypes.GET_MAJOR_FAILED,
    error,
  };
}

export function setCVTransactionId(data: string): actionIs.SetCvTransactionId {
  return {
    type: actionTypes.SET_CV_TRANSACTION_ID,
    data,
  };
}

export function cancelCvTransaction(data: { id: string }): actionIs.CancelCvTransaction {
  return {
    type: actionTypes.CANCEL_CV_TRANSACTION,
    data,
  };
}

export function cancelCvTransactionSuccess(): actionIs.CancelCvTransactionSuccess {
  return {
    type: actionTypes.CANCEL_CV_TRANSACTION_SUCCESS,
  };
}

export function cancelCvTransactionFailed(): actionIs.CancelCvTransactionFailed {
  return {
    type: actionTypes.CANCEL_CV_TRANSACTION_FAILED,
  };
}

export function getCvTemplateList(
  payload?: FilterCvTemplatePayload,
  callbacks?: ReduxUnfoldSaga.CallbacksType,
): actionIs.GetCvTemplateList {
  return { type: actionTypes.GET_LIST_CV_TEMPLATE, payload, callbacks };
}

export function getCvTransactionList(): actionIs.GetCvTransactionList {
  return { type: actionTypes.GET_CV_TRANSACTION_LIST };
}

export function getCvTransactionListSuccess(data: CvTransactionData[]): actionIs.GetCvTransactionListSuccess {
  return {
    type: actionTypes.GET_CV_TRANSACTION_LIST_SUCCESS,
    data,
  };
}

export function getCvTransactionListFailed(error: Error): actionIs.GetCvTransactionListFailed {
  return {
    type: actionTypes.GET_CV_TRANSACTION_LIST_FAILED,
    error,
  };
}

export function setCvSelected(data: CvTemplateData): actionIs.SetCvSelected {
  return {
    type: actionTypes.SET_CV_SELECTED,
    data,
  };
}

export function setCurrentCvColor(data: string): actionIs.SetCurrentCvColor {
  return {
    type: actionTypes.SET_CURRENT_CV_COLOR,
    data,
  };
}

export function setCurrentCvFont(data: string): actionIs.SetCurrentCvFont {
  return {
    type: actionTypes.SET_CURRENT_CV_FONT,
    data,
  };
}

export function setCurrentCvFontSize(data: number): actionIs.SetCurrentCvFontSize {
  return {
    type: actionTypes.SET_CURRENT_CV_FONT_SIZE,
    data,
  };
}

export function setShowAvatar(data: boolean): actionIs.SetShowAvatar {
  return {
    type: actionTypes.SET_SHOW_AVATAR,
    data,
  };
}

export function setShowProficiency(data: boolean): actionIs.SetShowProficiency {
  return {
    type: actionTypes.SET_SHOW_PROFICIENCY,
    data,
  };
}

export function getMyCVTemplateList(callbacks?: ReduxUnfoldSaga.CallbacksType): actionIs.GetMyCVTemplateList {
  return {
    type: actionTypes.GET_MY_CV_TEMPLATE_LIST,
    callbacks,
  };
}

export function getMyCVTemplateListSuccess(data: MyCVTemplateData[]): actionIs.GetMyCVTemplateListSuccess {
  return {
    type: actionTypes.GET_MY_CV_TEMPLATE_LIST_SUCCESS,
    data,
  };
}

export function getMyCVTemplateListFailed(error: Error): actionIs.GetMyCVTemplateListFailed {
  return {
    type: actionTypes.GET_MY_CV_TEMPLATE_LIST_FAILED,
    error,
  };
}

export function createMyCVTemplate(
  payload: CreateMyCVTemplatePayload,
  callbacks?: ReduxUnfoldSaga.CallbacksType,
): actionIs.CreateMyCVTemplate {
  return {
    type: actionTypes.CREATE_MY_CV_TEMPLATE,
    payload,
    callbacks,
  };
}

export function createMyCVTemplateSuccess(data: CreateMyCVTemplateData): actionIs.CreateMyCVTemplateSuccess {
  return {
    type: actionTypes.CREATE_MY_CV_TEMPLATE_SUCCESS,
    data,
  };
}

export function createMyCVTemplateFailed(error: Error): actionIs.CreateMyCVTemplateFailed {
  return {
    type: actionTypes.CREATE_MY_CV_TEMPLATE_FAILED,
    error,
  };
}

export function updateMyCVTemplate(
  payload: MyCVTemplateData,
  callbacks?: ReduxUnfoldSaga.CallbacksType,
): actionIs.UpdateMyCVTemplate {
  return {
    type: actionTypes.UPDATE_MY_CV_TEMPLATE,
    payload,
    callbacks,
  };
}

export function updateMyCVTemplateSuccess(data: UpdateMyCVTemplateData): actionIs.UpdateMyCVTemplateSuccess {
  return {
    type: actionTypes.UPDATE_MY_CV_TEMPLATE_SUCCESS,
    data,
  };
}

export function updateMyCVTemplateFailed(error: Error): actionIs.UpdateMyCVTemplateFailed {
  return {
    type: actionTypes.UPDATE_MY_CV_TEMPLATE_FAILED,
    error,
  };
}

export function deleteMyCVTemplate(payload: MyCVTemplateData): actionIs.DeleteMyCVTemplate {
  return {
    type: actionTypes.DELETE_MY_CV_TEMPLATE,
    payload,
  };
}

export function deleteMyCVTemplateSuccess(data: DeleteMyCVTemplateData): actionIs.DeleteMyCVTemplateSuccess {
  return {
    type: actionTypes.DELETE_MY_CV_TEMPLATE_SUCCESS,
    data,
  };
}

export function deleteMyCVTemplateFailed(error: Error): actionIs.DeleteMyCVTemplateFailed {
  return {
    type: actionTypes.DELETE_MY_CV_TEMPLATE_FAILED,
    error,
  };
}

export function getTerm(payload: { lang: string }): actionIs.GetTerm {
  return {
    type: actionTypes.GET_TERM,
    payload,
  };
}

export function getTermSuccess(data: { urbancv_tnc: string }): actionIs.GetTermSuccess {
  return {
    type: actionTypes.GET_TERM_SUCCESS,
    data,
  };
}

export function getTermFailed(error: Error): actionIs.GetTermFailed {
  return {
    type: actionTypes.GET_TERM_FAILED,
    error,
  };
}

export function getPolicy(payload: { lang: string }): actionIs.GetPolicy {
  return {
    type: actionTypes.GET_POLICY,
    payload,
  };
}

export function getPolicySuccess(data: { privacy_policy_urbancv: string }): actionIs.GetPolicySuccess {
  return {
    type: actionTypes.GET_POLICY_SUCCESS,
    data,
  };
}

export function getPolicyFailed(error: Error): actionIs.GetPolicyFailed {
  return {
    type: actionTypes.GET_POLICY_FAILED,
    error,
  };
}

export function getSampleCv(payload: SampleCVPayload): actionIs.GetSampleCvList {
  return {
    type: actionTypes.GET_SAMPLE_CV_LIST,
    payload,
  };
}

export function getSampleCvSuccess(data): actionIs.GetSampleCvListSuccess {
  return {
    type: actionTypes.GET_SAMPLE_CV_LIST_SUCCESS,
    data,
  };
}

export function getSampleCvFailed(error: Error): actionIs.GetSampleCvListFailed {
  return {
    type: actionTypes.GET_SAMPLE_CV_LIST_FAILED,
    error,
  };
}

export function getCvCategoryList(): actionIs.GetCvCategoryList {
  return {
    type: actionTypes.GET_CV_CATEGORY_LIST,
  };
}

export function getCvCategoryListSuccess(data): actionIs.GetCvCategoryListSuccess {
  return {
    type: actionTypes.GET_CV_CATEGORY_LIST_SUCCESS,
    data,
  };
}

export function getCvCategoryListFailed(error: Error): actionIs.GetCvCategoryListFailed {
  return {
    type: actionTypes.GET_CV_CATEGORY_LIST_FAILED,
    error,
  };
}

export function getSampleCvDetail(payload: SampleCVDetailPayload): actionIs.GetCvDetail {
  return {
    type: actionTypes.GET_CV_DETAIL_PAGE,
    payload,
  };
}

export function getSampleCvDetailSuccess(data): actionIs.GetCvDetailSuccess {
  return {
    type: actionTypes.GET_CV_DETAIL_PAGE_SUCCESS,
    data,
  };
}

export function getSampleCvDetailFailed(error: Error): actionIs.GetCvDetailFailed {
  return {
    type: actionTypes.GET_CV_DETAIL_PAGE_FAILED,
    error,
  };
}

export function getOccupationList(): actionIs.GetOccupationList {
  return {
    type: actionTypes.GET_OCCUPATION_LIST,
  };
}

export function getOccupationListSuccess(data): actionIs.GetOccupationListSuccess {
  return {
    type: actionTypes.GET_OCCUPATION_LIST_SUCCESS,
    data,
  };
}

export function getOccupationListFailed(error: Error): actionIs.GetOccupationListFailed {
  return {
    type: actionTypes.GET_OCCUPATION_LIST_FAILED,
    error,
  };
}

export function getOccupationDetails(data: { occupationId: number }): actionIs.GetOccupationDetails {
  return {
    type: actionTypes.GET_OCCUPATION_DETAILS,
    data,
  };
}

export function getOccupationDetailsSuccess(data): actionIs.GetOccupationDetailsSuccess {
  return {
    type: actionTypes.GET_OCCUPATION_DETAILS_SUCCESS,
    data,
  };
}

export function getOccupationDetailsFailed(error: Error): actionIs.GetOccupationDetailsFailed {
  return {
    type: actionTypes.GET_OCCUPATION_DETAILS_FAILED,
    error,
  };
}

export function resetOccupationDetails(): actionIs.ResetOccupationDetails {
  return {
    type: actionTypes.RESET_OCCUPATION_DETAILS,
  };
}

export function getSharedCvDetails(data: SharedCVDetailsPayload): actionIs.GetSharedCVDetails {
  return {
    type: actionTypes.GET_SHARED_CV_DETAIL,
    payload: data,
  };
}

export function getSharedCvDetailsSuccess(data): actionIs.GetSharedCVDetailsSuccess {
  return {
    type: actionTypes.GET_SHARED_CV_DETAIL_SUCCESS,
    data,
  };
}

export function getSharedCvDetailsFailed(error: Error): actionIs.GetSharedCVDetailsFailed {
  return {
    type: actionTypes.GET_SHARED_CV_DETAIL_FAILED,
    error,
  };
}

export function getJobScopeList(data: { keyword: string }, callbacks): actionIs.GetJobScopeList {
  return {
    type: actionTypes.GET_JOB_SCOPE_LIST,
    data,
    callbacks,
  };
}

export function getDegreeList(callbacks): actionIs.GetDegreeList {
  return {
    type: actionTypes.GET_DEGREE_LIST,
    callbacks,
  };
}

export function getSchoolList(data: { keyword: string }, callbacks): actionIs.GetSchoolList {
  return {
    type: actionTypes.GET_SCHOOL_LIST,
    data,
    callbacks,
  };
}

export function getJobTypeList(data: { keyword: string }, callbacks): actionIs.GetJobTypeList {
  return {
    type: actionTypes.GET_JOB_TYPE_LIST,
    data,
    callbacks,
  };
}

export function getCompanyList(data: { keyword: string; mode: string }, callbacks): actionIs.GetCompanyList {
  return {
    type: actionTypes.GET_COMPANY_LIST,
    data,
    callbacks,
  };
}

export function addReference(data: ProfileReference): actionIs.AddReference {
  return { type: actionTypes.ADD_REFERENCE, data };
}

export function getReference(): actionIs.GetReference {
  return { type: actionTypes.GET_REFERENCE };
}

export function updateReference(data: ProfileReference): actionIs.UpdateReference {
  return { type: actionTypes.UPDATE_REFERENCE, data };
}

export function updateReferenceOrdering(data: ProfileReference[]): actionIs.UpdateReferenceOrdering {
  return { type: actionTypes.UPDATE_REFERENCE_ORDERING, data };
}

export function deleteReference(data: { id: number }): actionIs.DeleteReference {
  return { type: actionTypes.DELETE_REFERENCE, data };
}

export function addCertificate(data: ProfileCertificate): actionIs.AddCertificate {
  return { type: actionTypes.ADD_CERTIFICATE, data };
}

export function getCertificate(): actionIs.GetCertificate {
  return { type: actionTypes.GET_CERTIFICATE };
}

export function updateCertificate(data: ProfileCertificate): actionIs.UpdateCertificate {
  return { type: actionTypes.UPDATE_CERTIFICATE, data };
}

export function updateCertificateOrdering(data: ProfileCertificate[]): actionIs.UpdateCertificateOrdering {
  return { type: actionTypes.UPDATE_CERTIFICATE_ORDERING, data };
}

export function deleteCertificate(data: { id: number }): actionIs.DeleteCertificate {
  return { type: actionTypes.DELETE_CERTIFICATE, data };
}

export function getLanguageList(callbacks): actionIs.GetLanguageList {
  return { type: actionTypes.GET_LANGUAGE_LIST, callbacks };
}

export function addLanguage(data: ProfileLanguage): actionIs.AddLanguage {
  return { type: actionTypes.ADD_LANGUAGE, data };
}

export function getLanguage(): actionIs.GetLanguage {
  return { type: actionTypes.GET_LANGUAGE };
}

export function updateLanguage(data: ProfileLanguage): actionIs.UpdateLanguage {
  return { type: actionTypes.UPDATE_LANGUAGE, data };
}

export function updateLanguageOrdering(data: ProfileLanguage[]): actionIs.UpdateLanguageOrdering {
  return { type: actionTypes.UPDATE_LANGUAGE_ORDERING, data };
}

export function deleteLanguage(data: { id: number }): actionIs.DeleteLanguage {
  return { type: actionTypes.DELETE_LANGUAGE, data };
}

export function getInterest(callbacks): actionIs.GetInterest {
  return { type: actionTypes.GET_INTEREST_LIST, callbacks };
}

export function addInterest(data: string[]): actionIs.AddInterest {
  return { type: actionTypes.ADD_INTEREST_LIST, data };
}

export function deleteInterest(idx: number): actionIs.DeleteInterest {
  return { type: actionTypes.DELETE_INTEREST_LIST, idx };
}

export function addVolunteer(data: ProfileVolunteer): actionIs.AddVolunteer {
  return { type: actionTypes.ADD_VOLUNTEER, data };
}

export function getVolunteer(): actionIs.GetVolunteer {
  return { type: actionTypes.GET_VOLUNTEER };
}

export function updateVolunteer(data: ProfileVolunteer): actionIs.UpdateVolunteer {
  return { type: actionTypes.UPDATE_VOLUNTEER, data };
}

export function updateVolunteerOrdering(data: ProfileVolunteer[]): actionIs.UpdateVolunteerOrdering {
  return { type: actionTypes.UPDATE_VOLUNTEER_ORDERING, data };
}

export function deleteVolunteer(data: { id: number }): actionIs.DeleteVolunteer {
  return { type: actionTypes.DELETE_VOLUNTEER, data };
}

export function getSkillList(data: { keyword: string }, callbacks): actionIs.GetSkillList {
  return { type: actionTypes.GET_SKILL_LIST, data, callbacks };
}

export function submitContactUs(payload: ContactUsPayload, callbacks): actionIs.SubmitContactUs {
  return {
    type: actionTypes.SUBMIT_CONTACT_US,
    payload: {
      ...payload,
    },
    callbacks,
  };
}

export function facebookLogin(payload: FacebookLoginPayload, callbacks): actionIs.FacebookLogin {
  return {
    type: actionTypes.FACEBOOK_LOGIN,
    payload: {
      ...payload,
    },
    callbacks,
  };
}

export function linkedInLogin(payload: LinkedInLoginPayload, callbacks): actionIs.LinkedInLogin {
  return {
    type: actionTypes.LINKEDIN_LOGIN,
    payload: {
      ...payload,
    },
    callbacks,
  };
}

export function getReferralDetail(): actionIs.GetReferralDetail {
  return {
    type: actionTypes.GET_REFERRAL_DETAIL,
  };
}

export function getReferralDetailSuccess(data: ReferralData): actionIs.GetReferralDetailSuccess {
  return {
    type: actionTypes.GET_REFERRAL_DETAIL_SUCCESS,
    data,
  };
}

export function getReferralDetailFailed(error: Error): actionIs.GetReferralDetailFailed {
  return {
    type: actionTypes.GET_REFERRAL_DETAIL_FAILED,
    error,
  };
}

export function addClicks(payload: AddClicksPayload): actionIs.AddClicks {
  return {
    type: actionTypes.ADD_CLICKS,
    payload: {
      ...payload,
    },
  };
}
